import { useStaticQuery, graphql } from 'gatsby'
import FragmentHarmonogramIndex from '../../../model/fragments/allNodeHarmonogram/harmonogram-index'

export default () => {
    const data = useStaticQuery(graphql`
        query {
            index: allNodeHarmonogram
#            (
#                filter: {
#                    field_harmonogram_category: {
#                        eq: "trainer"
#                    }
#                }
#                sort: {
#                    fields: [created]
#                    order: DESC
#                }
#            )
            {
                edges {
                    node {
                        ...FragmentHarmonogramIndex
                    }
                }
            }
        }
    `);

//     const data = useStaticQuery(graphql`
//         query {
//             index: allNodeHarmonogram (
// #                limit: 5,
//                 filter: {
// #                    promote: {
// #                        eq: true
// #                    }
// #                    langcode: {
// #                        eq: "pl"
// #                    }
//                     relationships: {
//                         field_lectures: {
//                             field_date_from: {
//                                 elemMatch: {
//                                     name: {
//                                         eq: $tag
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }
// #                sort: {
// #                    fields: [created]
// #                    order: DESC
// #                }
//             ) {
//                 edges {
//                     node {
//                         ...FragmentHarmonogramIndex
//                     }
//                 }
//             }
//         }
//     `);

    return data;
}
