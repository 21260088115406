// import _ from "lodash"
// import { globalHistory } from "@reach/router"
import GeneralUtils from './../utils/common'



class Harmonogram {
    constructor(node, intl) {
        // this.node = node;
        // this.intl = intl;

        this.arguments = {
            node,
            intl,
        }
    }

    getDate = () => {
        const { node, intl } = this.arguments;
        const t = intl.formatMessage;

        if (node.field_date) {
            return node.field_date;
        }

        let dateFrom = null;
        let dateTo = null;

        if (node.relationships.field_lectures.length) { // it's an [] if there's no lectures
            dateFrom = node.relationships.field_lectures[0].field_date_from;
            dateTo = node.relationships.field_lectures[node.relationships.field_lectures.length - 1].field_date_to;
            
            return `${GeneralUtils.dateFormat(dateFrom, null, intl.locale)} - ${GeneralUtils.dateFormat(dateTo, null, intl.locale)}, ${GeneralUtils.capitalizeFirstLetter(t({ id: "soft4net_node_harmonogram_lectures" }))}: ${node.relationships.field_lectures.length}`;
        }

        return null;
    }

    canReserve = () => {

    }
}

export default Harmonogram